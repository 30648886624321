const dayjs = require('dayjs')
const year = dayjs().format('YYYY')
module.exports = {
  name: 'Higher Eagle',
  manifest: {
    themeColor: '#1190cb',
    backgroundColor: '#000000',
  },
  locales: {
    list: ['zh-TW', 'zh-CN', 'en-US'],
    defaultLocale: 'zh-TW',
    defaultTimeZone: 'Asia/Taipei',
  },
  seo: {
    keywords: 'Higher Eagle',
    description: `透過軟體改變世界, ${year}一起扶搖直上, Rising High`,
  },
  sitemap: {
    types: [
      'page',
    ],
  },
  feature: {
    // 商品分類最多階層數
    productCategoryMaxDepth: 2,

    // SMS功能
    smsModule: false,

    /**
     * 客服模組
     */
    contactModule: true,

    /**
     * 各頁面的階層設定(頁面index sidebar使用)
     */
    pageTypes: {
      default: { depth: 1 },
      article: { depth: 1 },
      product: { depth: 2 },
      project: { depth: 1 },
    },
  },
}
