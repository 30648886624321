export default {
  'module.page': '頁面',
  'page.page': '頁面',
  'page.page-category': '頁面分類',
  'page.type.default': '頁面',
  'page.type.article': '文章',
  'page.type.project': '專案',
  'page.type.product': '商品',

  // role
  'ROLE_PAGE': '頁面管理',

  // data
  'page.search_keyword': '輸入標題、簡述、內容搜尋',
  'page.data.code': '頁面代碼',
  'page.data.brief': '簡述',
  'page.data.locked': '上鎖',
  'page.data.publish_start': '發布起始時間',
  'page.data.publish_end': '發布結束時間',
  'page.data.password': '頁面密碼',
  'page.data.password.help': '當頁面未公開的情況下, 可透過此密碼產生的特殊連結供使用者查看頁面, 或作為預覽之用途',
  'page.data.photo_contain': '圖片是否完整顯示',
  'page.data.photo_contain.help': '此功能主要用於前台列表顯示的圖片, 若啟用將能夠完整顯示整張圖片(可能會有空白, 適合有白邊的圖片使用), 停用則會將圖片撐滿(不會有空白)',
  'page.data.sitemap': '加入sitemap',

  // form

  // action
  'page.action.copy_secret_link': '複製加密連結',

  // others
}
