import Vue from 'vue'
import { EagleModule } from 'kernel/types/module'
import contactConstants from 'modules/contact/config/contactConstants'

class module {
  private vm: Vue| undefined
  public name: string
  constructor() {
    this.name = 'contact'
  }

  public initVue(vm: Vue) {
    this.vm = vm
  }

  async launch(vm: Vue) : Promise<void>{
    Vue.prototype.$contactConstants = contactConstants
    Vue.prototype.$contactType = [
      contactConstants.TYPE_DEFAULT,
      contactConstants.TYPE_SOFTWARE,
      contactConstants.TYPE_QUOTATION,
      contactConstants.TYPE_COLLABORATION,
      contactConstants.TYPE_TRY,
    ]
    Vue.prototype.$contactStatus = [
      contactConstants.STATUS_CREATED,
      contactConstants.STATUS_IN_PROGRESS,
      contactConstants.STATUS_CLOSED,
    ]
  }

  getModuleConfig() : EagleModule {
    return {
      launch: this.launch,
      menu: () => import('modules/contact/config/menu'),
      realApi: () => import('modules/contact/config/realApi'),
      routes: () => import('modules/contact/config/route'),
    }
  }
}

export default new module()
