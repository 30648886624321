export default {
  // 角色
  ROLE_USER: 'user', // 使用者
  ROLE_SITE_ADMIN: 'site_admin', //管理員

  // 類型
  TYPE_DEFAULT: 'default', // 預設(聯絡我們)
  TYPE_SOFTWARE: 'software', // 軟體開發
  TYPE_QUOTATION: 'quotation', // 取得報價
  TYPE_COLLABORATION: 'collaboration', // 商業合作
  TYPE_TRY: 'try', // 軟體試用

  // 狀態
  STATUS_CREATED: 'created', // 新建立
  STATUS_IN_PROGRESS: 'in-progress', // 處理中
  STATUS_CLOSED: 'closed', // 結案
}
