import { zhHant } from 'vuetify/src/locale'
export default {
  $vuetify: {
    ...zhHant,
    'noDataText': '無資料',
  },

  'action': '操作',
  'alert': '系統訊息',
  'network.online': '網路已重新連線',
  'network.offline': '網路已斷線, 請重新連線確保網站運作正常',
  'page.404': '找不到此頁面',

  'keep.login': '保持登入狀態',
  'keep.login.help': '請勿在公共電腦使用此功能',
  'remember.email': '記住我的Email',
  'remember.email.help': '下次登入不需重新輸入Email',
  'fill.data.and.send_it_out': '資料填寫完整即可送出',

  // send mail
  'send_mail.successfully': 'Email已成功發送',
  'error.send_mail.failure': 'Email發送失敗',
  'error.send_reset_password_mail.404': 'email找不到任何帳號',
  'error.send_reset_password_mail.409': '還有未過期的驗證碼',

  // action
  'action.export': '匯出',
  'action.preview': '預覽',
  'action.view': '檢視',
  'action.copy': '複製',
  'action.copy_link': '複製連結',
  'action.login': '登入',
  'action.logout': '登出',
  'action.back': '回上一頁',
  'action.view_password': '顯示密碼',
  'action.apply': '確定',
  'action.submit': '送出',
  'action.save': '儲存',
  'action.edit': '編輯',
  'action.create': '新增',
  'action.update': '更新',
  'action.move': '移動',
  'action.remove': '移除',
  'action.delete': '刪除',
  'action.close': '關閉',
  'action.cancel': '取消',
  'action.batch_delete': '批次刪除',
  'action.refresh': '重新整理',
  'action.start_search': '搜尋',
  'action.filter': '篩選',
  'action.batch': '批次操作',
  'action.select_all': '全選',
  'action.cancel_select_all': '取消全選',
  'action.keyword': '輸入關鍵字',
  'action.sort_key': '排序依據',
  'action.sort_asc': '遞增',
  'action.sort_desc': '遞減',
  'action.time_range': '請選擇時間區間',
  'action.start_filter': '開始篩選',
  'action.clean_filter': '清除篩選',
  'action.tree_node.move.before': '移至指定節點前',
  'action.tree_node.move.after': '移至指定節點後',
  'action.tree_node.move.inside': '移至指定節點內',
  'action.tree_node.move.up': '往上一層移動',
  'action.tree_node.create.top_level': '新增第一層節點',
  'action.tree_node.create.help': '輸入節點名稱',
  'action.toggle.open': '收合',
  'action.upload': '上傳',
  'action.upload-photo': '圖片上傳',
  'action.forgot-password': '忘記密碼',
  'action.reset-password': '重設密碼',
  'action.account-verify': '核對身份',
  'action.account-verify.help': '請輸入管理員密碼',
  'action.play-video': '影片預覽',
  'action.tinymce.upload_photos': '一次上傳多張圖片',
  'action.tinymce.mark': '重點文字',
  'action.more': 'MORE',
  'action.reply': '回覆',
  'action.download': '下載',
  'action.download_original_photo': '下載原圖',
  'action.create_random_uid': '產生隨機代碼',

  // photo
  'photo.action.edit_name': '編輯圖片名稱',
  'photo.action.select_size': '請選擇一個尺寸繼續接下來的操作',
  'photo.validation.image.incorrect': '圖片格式不正確',
  'photo.upload.success': '圖片上傳成功',
  'photo.upload.fail': '圖片上傳失敗',

  // file
  'file.size': '檔案大小',
  'file.action.upload': '上傳檔案',
  'file.help.max_size_limit': '檔案大小不可超過{max}MB',
  'file.help.max_quantity_limit': '最多{quantity}個檔案',
  'file.upload.successfully': '上傳成功',
  'file.upload.successfully.content': '共{quantity}個檔案',
  'file.upload.failure': '上傳失敗',
  'file.warning.max_size_limit': '檔案大小超過限制',

  // export excel
  'export_excel.successfully': 'Excel匯出成功',
  'export_excel.failure': 'Excel匯出失敗',
  'export_excel.error.index_failure': '讀取資料發生錯誤',

  // reply
  'reply.successfully': '回覆成功',
  'reply.failure': '回覆失敗',

  // confirm
  'export_excel.confirm': '確定進行Excel檔案匯出?',
  'download.confirm': '確定要下載檔案?',
  'update.confirm': '確定要更新?',
  'batch.confirm': '確定要執行此批次操作?',
  'batch_delete.confirm': '確定要執行批次刪除?',
  'remove.confirm': '確定要移除?',
  'file.remove.confirm': '確定要移除已上傳的檔案?',
  'delete.confirm': '確定要刪除?',
  'logout.confirm': '確定要登出?',
  'exit.confirm': '確定要離開此操作畫面?',
  'refresh.confirm': '確定要執行重新整理?',
  'refresh.confirm.content': '請確認資料儲存後再進行',
  'upload_clipboard.confirm': '確定要上傳剪貼簿的圖片?',
  'upload_clipboard.confirm.content': '共{quantity}張',

  // data
  'data.name': '名稱',
  'data.user': '使用者',
  'data.person_name': '姓名',
  'data.photo': '圖片',
  'data.photo_mobile': '手機版圖片',
  'data.photo_mobile.help': '若未設定手機版圖片, 則直接使用一般圖片',
  'data.avatar': '頭像',
  'data.file': '檔案',
  'data.banner': 'Banner',
  'data.amount': '金額',
  'data.title': '標題',
  'data.link': '連結',
  'data.target_blank': '另開分頁',
  'data.brief': '敘述',
  'data.description': '敘述',
  'data.category': '分類',
  'data.content': '內容',
  'data.profile': '帳號',
  'data.keywords': '關鍵字',
  'data.status': '狀態',
  'data.status.true': '啟用',
  'data.status.false': '停用',
  'data.status.success': '成功',
  'data.status.failure': '失敗',
  'data.true': '是',
  'data.false': '否',
  'data.categories': '分類',
  'data.created_at': '建立時間',
  'data.updated_at': '更新時間',
  'data.tree_node.name': '節點名稱',
  'data.node.empty': '目前無任何節點',
  'data.empty': '目前無任何資料',
  'data.none': '無',
  'data.email': 'Email',
  'data.phone': '手機號碼',
  'data.password': '密碼',
  'data.password_confirm': '確認密碼',
  'data.code': '代碼',
  'data.reply_content': '回覆內容',
  'data.ip': 'IP',
  'data.total': '總計',
  'data.file_size': '檔案大小',
  'data.unit': '單位',
  'data.time': '時間',
  'data.quantity': '數量',
  'data.note': '備註',

  'data.storage_info.photo': '圖片使用大小',
  'data.storage_info.file': '檔案使用大小',
  'data.storage_info.max_gb': '可用空間(GB)',

  // message
  'go_to.frontend': '前往前台',
  'message.greeting.title': 'Hi {name} !',
  'message.greeting.content': '<p>歡迎回到{site}</p><p>為確保系統最佳使用體驗</p><p>我們建議您使用Google Chrome瀏覽器</p>',

  // notify
  'notify.recaptcha.failure': 'Google Recaptcha驗證失敗',
  'notify.account-verify.successfully': '身份驗證成功',
  'notify.account-verify.failure': '身份驗證失敗',
  'notify.account-verify.failure.content': '請再次驗證',
  'notify.login.successfully': '登入成功',
  'notify.login.failure': '登入失敗',
  'notify.login.failure.incorrect': '帳號或密碼錯誤',
  'notify.login.failure.locked': '此帳號為黑名單',
  'notify.welcome': 'Hi {name} ! 歡迎回來',
  'notify.tree.move.failure': '節點移動失敗',
  'notify.tree.move.failure.content': '不可移入自己的後代內或是發生意外的錯誤',

  // data load
  'data_load.failure': '資料讀取失敗',
  'data_load.failure.content': '找不到該筆資料',

  // save
  'save.successfully': '儲存成功',
  'save.failure': '儲存失敗',
  'save.failure.unfinished': '資料未填寫完整',
  'save.failure.data_incorrect': '請檢查有問題的欄位調整後再提交',

  // batch
  'batch.successfully': '批次操作成功',
  'batch.failure': '批次操作失敗',
  'batch_delete.successfully': '批次刪除成功',
  'batch_delete.failure': '批次刪除失敗',

  // form
  'form_block.base': '基本資料',
  'form_block.publish': '發布設定',
  'form_block.others': '其他',
  'form_status.changed': '資料異動',
  'form_status.unchanged': '資料無異動',
  'form_status.unfinished': '資料不完整',

  // delete
  'delete.successfully': '刪除成功',
  'delete.failure': '刪除失敗',

  // error
  'error.unexpect': '發生意外的錯誤, 請再試一次',
  'error.server_fail': 'API伺服器連線失敗',
  'error.server_fail.content': '請洽網站開發人員',
  'error.token_unauthorized': '重新登入',
  'error.token_unauthorized.content': '系統已長時間未使用，或是已經被登出，請重新登入',

  // validate
  'validate.error.required': '此欄位為必填',
  'validate.error.whitespace': '不可使用空白',
  'validate.error.invalid_char': '不可使用字元 {string}',
  'validate.error.email_incorrect': 'Email格式不正確',
  'validate.error.text_too_less': '字數最少{length}字',
  'validate.error.password_format_incorrect': '必須至少4個字元',
  'validate.error.password_not_match': '密碼不一致',
  'validate.error.phone_number_incorrect': '手機號碼格式不正確',

  // list
  'list.display_mode': '檢視模式',
  'list.display_mode.card': '卡片',
  'list.display_mode.table': '表格',
  'list.filter': '進階篩選',
  'list.filter.selector.multiple_mode.item_empty': '請選擇資料來篩選',
  'list.per_page': '每頁筆數',

  // pager
  'pager.page': '{page}/{pages}頁',
  'pager.total': '共{total}筆',

  // help
  'unselect': '未選擇',
  'password.help': '密碼必須至少四個字元',
  'combobox.help': '輸入資料後按Enter鍵可新增選項, 點擊x符號可刪除選項',
  'tree.max_depth.help': '最多可設定{max}層',
  'keyword.help': '每個關鍵字請使用逗號,隔開',
  'strict_string_check.help': '不可有空白或特殊符號 ^ \' ` ! @ # % & * + \\ " < >|_ [ ] 。',

  // others
  'copy.successfully': '複製成功',
  'batch.helper': '批次操作的資料如下',
  'batch.helper.disabled': '有刪除線的資料則代表不允許此批次操作',
  'batch.none': '無任何可操作的資料, 請重新選取允許的資料',
  'html-editor.action.kbd': '鍵盤符號',
  'photo_manager': '圖片管理',
  'file_manager': '檔案管理',
  'text.bytes': '共{length}字元',
  'date_range.limit': '時間範圍最多不得超過{max}天',
  'amount.format': '{amount}元',
  'upload_clipboard': '上傳剪貼簿內複製的圖片',
  'upload_clipboard.help': '請輸入Ctrl+V進行圖片上傳',

  // unit
  'unit.second': '秒',
  'unit.ms': '毫秒',
}
